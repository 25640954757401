exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-adsense-jsx": () => import("./../../../src/pages/adsense.jsx" /* webpackChunkName: "component---src-pages-adsense-jsx" */),
  "component---src-pages-appsshowcase-jsx": () => import("./../../../src/pages/appsshowcase.jsx" /* webpackChunkName: "component---src-pages-appsshowcase-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-dashboard-jsx": () => import("./../../../src/pages/dashboard.jsx" /* webpackChunkName: "component---src-pages-dashboard-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-febriansyah-rizki-jsx": () => import("./../../../src/pages/@febriansyah.rizki.jsx" /* webpackChunkName: "component---src-pages-febriansyah-rizki-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-templates-author-post-index-js": () => import("./../../../src/templates/author-post/index.js" /* webpackChunkName: "component---src-templates-author-post-index-js" */),
  "component---src-templates-categories-post-index-js": () => import("./../../../src/templates/categories-post/index.js" /* webpackChunkName: "component---src-templates-categories-post-index-js" */),
  "component---src-templates-date-post-index-js": () => import("./../../../src/templates/date-post/index.js" /* webpackChunkName: "component---src-templates-date-post-index-js" */),
  "component---src-templates-single-post-index-js": () => import("./../../../src/templates/single-post/index.js" /* webpackChunkName: "component---src-templates-single-post-index-js" */),
  "component---src-templates-tag-post-index-js": () => import("./../../../src/templates/tag-post/index.js" /* webpackChunkName: "component---src-templates-tag-post-index-js" */)
}

