import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBHTwb20jXT2veu_5blZeMUikrdgodCE9A",
    authDomain: "zegveld-7ec08.firebaseapp.com",
    projectId: "zegveld-7ec08",
    storageBucket: "zegveld-7ec08.firebasestorage.app",
    messagingSenderId: "921927975300",
    appId: "1:921927975300:web:9a50d739c29da86eae1dd1",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
